import React, { useEffect, useState, useRef } from "react"
import {
  Card,
  Button,
  Table,
  message,
  Select,
  Image as AntdImage,
  Modal,
  Drawer,
  Checkbox,
  Tooltip,
  Spin,
} from "antd"
import * as OrphanServer from "../../services/orphan"
import { useHistory } from "react-router-dom"
import styles from "./Index.module.css"
import PubInput from "../../../components/PubInput"
import { PubRangePicker } from "../../../components/PubRangePicker"
import {
  SettingOutlined,
  CaretRightOutlined,
  PauseOutlined,
  WarningOutlined,
} from "@ant-design/icons"
import dayjs from "dayjs"
import Hls from "hls.js"
import { render } from "@testing-library/react"
const { Option } = Select
//////////////////////////
// 播放器实例
// var player = null
window.__SHOW_LOG__ = true

// Promise封装实例释放
// function playerDestroyPromise() {
//   return new Promise((resolve, reject) => {
//     if (!player) {
//       resolve("OK")
//     }
//     player.stop()
//     player
//       .destroy()
//       .then(() => {
//         resolve("OK")
//       })
//       .catch((e) => {
//         reject(e)
//       })
//   })
// }

// // 同步方式等待释放
// async function playerDestroy() {
//   try {
//     await playerDestroyPromise()
//     player = null
//   } catch (e) {
//     console.error(e)
//   }
// }

let NewColumnsTitle = [
  { title: "生成时间", isShow: true },
  { title: "商品名称", isShow: true },
  { title: "商品总价(元)", isShow: true },
  { title: "商品条码", isShow: true },
  { title: "商品plu", isShow: true },
  { title: "商品单价（元）", isShow: true },
  { title: "称重重量", isShow: true },
  { title: "打秤时间", isShow: true },
  { title: "收银时间", isShow: true },
  { title: "超时x分未收银为丢失商品", isShow: true },
  { title: "所属门店", isShow: true },
  { title: "秤号", isShow: true },
  { title: "打秤设备激活码", isShow: true },
  { title: "称重图片", isShow: true },
  { title: "打秤设备录像", isShow: true },
  { title: "标记", isShow: true },
]

//////////////////////////
function Index(props) {
  const myRef = useRef(null)
  var hls = new Hls()
  let history = useHistory()
  //
  const [PlayingLoading, setPlayingLoading] = useState(false) // 录像Loading
  const [PlayScale, setPlayScale] = useState(1)
  const [PlayStop, setPlayStop] = useState(false)
  const [PlayError, setPlayError] = useState(false) // 错误捕获
  const [PlayErrorText, setPlayErrorText] = useState("") // 错误文字
  //
  const [columsOpen, setColumsOpen] = useState(false)
  const [columsData, setColumsData] = useState([])
  const [columsTitle, setColumsTitle] = useState(
    localStorage.getItem("OrphangoodsColumns")
      ? JSON.parse(localStorage.getItem("OrphangoodsColumns"))
      : NewColumnsTitle
  )
  //
  const [typeNum, setTypeNum] = useState("")
  const [orphanList, setOrthanList] = useState([]) // 丢失商品列表
  const [shopList, setShopList] = useState([]) // 门店列表
  const [total, setTotal] = useState(0)
  const [loading, setLoading] = useState(false)
  const [isModalOpen, setIsModalOpen] = useState(false) // 查看录像Modal框状态
  const [ParamsInfo, setParamsInfo] = useState({
    pageNum: 1,
    pageSize: 20,
    skuName: undefined, // 商品名称
    shopId: undefined, // 门店ID
    mark: undefined, // 标记
    timeArr: [dayjs().subtract(1, "month"), dayjs()], // 总时间
    startDate:
      dayjs().subtract(1, "month").format("YYYY-MM-DD") + " " + "00:00:00", // 结束时间
    endDate: dayjs().format("YYYY-MM-DD") + " " + "23:59:59", // 开始时间
    barCode: undefined, // 商品条码
  })

  // REF,动态值，影响表格接口刷新
  const refInfo = useRef({
    ParamsInfoRef: {}, //点击查询后的条件值
  })

  useEffect(() => {
    refInfo.current = {
      ParamsInfoRef: ParamsInfo,
    }
  }, [ParamsInfo])

  // 初始化
  useEffect(() => {
    setParamsInfo({ ...ParamsInfo, pageNum: 1 })
    //丢失列表接口，
    setTimeout(getOrphan, 0)
    // 门店列表
    getShopList()
  }, [])

  /**
   * 动态表头存储
   */
  useEffect(() => {
    if (
      localStorage.getItem("OrphangoodsColumns") &&
      JSON.parse(localStorage.getItem("OrphangoodsColumns")).length ==
        NewColumnsTitle.length
    ) {
      localStorage.setItem("OrphangoodsColumns", JSON.stringify(columsTitle))
    } else {
      localStorage.setItem(
        "OrphangoodsColumns",
        JSON.stringify(NewColumnsTitle)
      )
    }

    let NewColumns = [
      {
        title: "生成时间",
        dataIndex: "createTime",
        key: "createTime",
        ellipsis: { showTitle: true },
        width: 160,
      },
      {
        title: "商品名称",
        dataIndex: "skuName",
        key: "skuName",
        ellipsis: { showTitle: true },
        width: 160,
      },
      {
        title: "商品plu",
        dataIndex: "skuCode",
        key: "skuCode",
        ellipsis: { showTitle: true },
        width: 160,
      },
      {
        title: "商品单价（元）",
        dataIndex: "salePrice",
        key: "salePrice",
        ellipsis: { showTitle: true },
        width: 160,
      },
      {
        title: "称重重量",
        dataIndex: "saleWeight",
        key: "saleWeight",
        ellipsis: { showTitle: true },
        width: 160,
      },
      {
        title: "商品总价(元)",
        dataIndex: "amount",
        key: "amount",
        ellipsis: { showTitle: true },
        width: 160,
      },
      {
        title: "商品条码",
        dataIndex: "barCode",
        key: "barCode",
        ellipsis: { showTitle: true },
        width: 160,
      },
      {
        title: "打秤时间",
        dataIndex: "weightTime",
        key: "weightTime",
        ellipsis: { showTitle: true },
        width: 200,
      },
      {
        title: "收银时间",
        dataIndex: "cashierTime",
        ellipsis: { showTitle: true },
        key: "cashierTime",
        width: 200,
      },
      {
        title: "超时x分未收银为丢失商品",
        dataIndex: "timeoutMinute",
        ellipsis: { showTitle: true },
        key: "timeoutMinute",
        width: 200,
      },
      {
        title: "所属门店",
        dataIndex: "shopName",
        key: "shopName",
        ellipsis: { showTitle: true },
        width: 200,
      },
      {
        title: "秤号",
        dataIndex: "scaleNo",
        key: "scaleNo",
        ellipsis: { showTitle: true },
        width: 160,
      },
      {
        title: "打秤设备激活码",
        dataIndex: "cdKeyCode",
        key: "cdKeyCode",
        ellipsis: { showTitle: true },
        width: 200,
      },
      {
        title: "称重图片",
        dataIndex: "imageUrl",
        key: "imageUrl",
        ellipsis: { showTitle: true },
        width: 160,
        render: (info) => {
          return (
            <div className={styles.page_table_img}>
              <AntdImage
                src={info}
                style={{ objectFit: "cover", maxWidth: "160px" }}
              />
            </div>
          )
        },
      },
      {
        title: "打秤设备录像",
        dataIndex: "weightTime",
        key: "weightTime",
        ellipsis: { showTitle: true },
        width: 160,
        fixed: "right",
        render: (info, rowInfo) => {
          return (
            <>
              <Button
                type="link"
                onClick={() => {
                  setPlayingLoading(false)
                  setPlayScale(1)
                  setPlayStop(false)
                  setPlayError(false)
                  setTypeNum(2)
                  let NowDate = dayjs(dayjs(info)) || dayjs()
                  let newStart = NowDate.subtract(1, "minute")
                    .toDate()
                    .valueOf()
                  let newEnd = NowDate.add(1, "minute").toDate().valueOf()
                  const params = {
                    startTime: newStart,
                    endTime: newEnd,
                    deviceSn: rowInfo?.deviceSn || "",
                  }
                  setIsModalOpen(true)
                  setTimeout(() => {
                    getPlaybackCamera(params)
                  }, 100)
                }}
              >
                查看
              </Button>
            </>
          )
        },
      },
      {
        title: "标记",
        dataIndex: "mark",
        key: "mark",
        ellipsis: { showTitle: true },
        width: 180,
        fixed: "right",
        render: (info, rowInfo) => {
          return (
            <>
              <Select
                className={info == 1 ? styles.page_table_select : ""}
                getPopupContainer={(triggerNode) => triggerNode.parentNode}
                placeholder="请选择标记"
                style={{ width: 150 }}
                // allowClear
                value={info}
                showSearch
                filterOption={(i, o) => o.props.children.includes(i)}
                onChange={(e) => {
                  onGetorphanGoodMark(e, rowInfo?.id)
                }}
              >
                <Option value={1} key={1} style={{ color: "red" }}>
                  未找回
                </Option>
                <Option value={2} key={2}>
                  无需找回-已收银
                </Option>
                <Option value={3} key={3}>
                  已找回
                </Option>
              </Select>
            </>
          )
        },
      },
    ]
    const filteredNewColumns = NewColumns.filter((column) =>
      columsTitle.some(
        (titleItem) => titleItem.title === column.title && titleItem.isShow
      )
    )
    filteredNewColumns.push({
      title: (
        <Tooltip title="列表字段设置">
          <SettingOutlined
            style={{ fontSize: "15px" }}
            onClick={() => {
              setColumsOpen(true)
            }}
          />
        </Tooltip>
      ),
      ellipsis: { showTitle: true },
      fixed: "right",
      width: 50,
      render: () => null,
    })
    setColumsData(filteredNewColumns)
  }, [columsTitle])

  // // 销毁视cavas
  // useEffect(() => {
  //   if (!isModalOpen) {
  //     // 销毁视cavas
  //     console.log(222)
  //     return playerDestroy
  //   }
  // }, [!isModalOpen])
  // 销毁视cavas
  const getPlayer = () => {
    return document.getElementById("video-container")?.value
  }
  useEffect(() => {
    if (!isModalOpen) {
      // 销毁视cavas
      var player = getPlayer()
      if (player) {
        player.stop()
        player.destroy().then(() => {
          console.log("销毁")
        }) // 销毁
      }
    }
  }, [!isModalOpen])

  /**
   * 丢失商品列表
   */
  const getOrphan = async () => {
    try {
      const { ParamsInfoRef } = refInfo?.current
      const params = {
        ...ParamsInfoRef,
        timeArr: null,
      }
      setLoading(true)
      const { code, data, msg } = await OrphanServer.orphan_good_list(params)
      if (code != 200 || data === null) {
        message.error(msg)
        setLoading(false)
        return
      }
      setOrthanList(data.list)
      setTotal(data.total)
      setLoading(false)
    } catch (error) {
      message.error("称重规则列表获取失败")
      setLoading(false)
    }
  }
  /**
   * 导出数据
   */
  const onExpoerr = async () => {
    try {
      if (orphanList.length == 0) {
        message.error("暂无数据可导出")
        return
      }
      const { ParamsInfoRef } = refInfo?.current
      const params = {
        ...ParamsInfoRef,
        timeArr: null,
      }
      const res = await OrphanServer.GoodsExport(params)
      console.log(res)
      if (!res) return
    } catch (error) {
      console.log(error)
    }
  }

  /**
   * 查询
   */
  const handlSearch = async () => {
    try {
      //页码为1
      setParamsInfo({ ...ParamsInfo, pageNum: 1 })
      //丢失商品列表
      setTimeout(getOrphan, 0)
    } catch (error) {
      message.error(error)
      return
    }
  }

  /**
   * 获取门店列表
   */
  const getShopList = async (index) => {
    try {
      const params = {
        pageNum: 1,
        pageSize: 1000,
        code: "",
      }
      const { code, msg, data } = await OrphanServer.Shop_List(params)
      if (code != 200) {
        message.error(msg)
        return
      }
      setShopList(data?.list || [])
    } catch (error) {
      console.log(error)
    }
  }

  /**
   * 重置
   */
  const handClearn = async () => {
    try {
      setParamsInfo({
        pageNum: 1,
        pageSize: 20,
        skuName: undefined, // 商品名称
        shopId: undefined, // 门店ID
        mark: undefined, // 标记
        timeArr: [dayjs().subtract(1, "month"), dayjs()], // 总时间
        startDate:
          dayjs().subtract(1, "month").format("YYYY-MM-DD") + " " + "00:00:00", // 结束时间
        endDate: dayjs().format("YYYY-MM-DD") + " " + "23:59:59", // 开始时间
        barCode: undefined, // 商品条码
      })
      //丢失商品列表
      setTimeout(getOrphan, 0)
    } catch (error) {
      console.log(error)
      return
    }
  }

  //分页
  const onTableChange = () => {
    const paginationProps = {
      showSizeChanger: false,
      showQuickJumper: false,
      showTotal: () => `共${total}条`,
      pageSize: ParamsInfo.pageSize,
      current: ParamsInfo.pageNum,
      total: total,
      onChange: (current) => {
        setParamsInfo({ ...ParamsInfo, pageNum: current })
        // 丢失商品列表
        setTimeout(getOrphan, 0)
      },
    }
    return paginationProps
  }
  /**
   * 前进10秒
   */
  const onSecond = () => {
    var player = getPlayer()
    // currentTime返回的是毫秒
    const currentTime = player.getPlaybackTime()
    // 增加30秒
    const newTime = currentTime + 10000
    player.setPlaybackConfig({
      startTime: newTime, // 跳转到指定时间开始回放
    })
    setPlayStop(false)
  }
  /**
   * 后退10秒
   */
  const onBack = () => {
    var player = getPlayer()
    // currentTime返回的是毫秒
    const currentTime = player.getPlaybackTime()
    // 后退10秒
    const newTime = currentTime - 10000
    player.setPlaybackConfig({
      startTime: newTime, // 跳转到指定时间开始回放
    })
    setPlayStop(false)
  }

  /**
   * 监控回放
   */
  const getPlaybackCamera = async (params) => {
    try {
      setPlayingLoading(true)
      const { code, data, msg } = await OrphanServer.PlaybackCamera(params)
      if (code != 200) {
        message.error(msg)
        setPlayingLoading(false)
        return
      }
      setTypeNum(data.type)
      //  海康
      if (data && data.playUrl && data.type == 1) {
        console.log(data)
        if (Hls.isSupported()) {
          hls.loadSource(data.playUrl)
          // hls.loadSource(
          //   "https://hw-m-l.cztv.com/channels/lantian/channel002/1080p.m3u8"
          // )
          hls.attachMedia(myRef.current)
          hls.on(Hls.Events.MANIFEST_PARSED, function () {
            myRef.current.play()
          })
        } else {
          message.error("不支持HLS播放器")
        }
      } else if (data && data.playUrl && data.type == 2) {
        // TP-LINK商用云平台
        var TumsPlayer = window["tums-player"].default
        var container = document.getElementById("video-container")
        var player = new TumsPlayer(container, {
          type: "relay", // 协议类型，rtsp/relay
          url: data.playUrl ? data.playUrl : "", // 取流地址
          pluginPath: "https://smbcloud.tp-link.com.cn/static",
          streamType: "sdvod",
          autoplay: true,
          eventType: [1, 2],
          startTime: params.startTime, // 跳转到指定时间开始回放
          endTime: params.endTime,
          userId: data.userId,
          scale: 1,
        })
        // 监听播放器加载完成事件
        player.on("ready", function (evt) {
          console.log("监听播放器加载完成事件")
          console.log(player.getPlaybackTime(), "监听播放器加载完成事件")
          setPlayingLoading(false)
        })
        // 监听播放器错误事件
        player.on("error", function (e) {
          // alert("播放器发生错误")
          setPlayError(true)
          setPlayingLoading(false)
          setPlayErrorText("达到带宽限制")
        })
        container.value = player
      } else {
        //异常
        message.error("异常")
      }
    } catch (error) {
      console.log(error)
      setPlayError(true)
      setPlayingLoading(false)
      setPlayErrorText("出错了！")
    }
  }

  /**
   * 标记操作
   */
  const onGetorphanGoodMark = async (mark, id) => {
    try {
      const params = { id, mark }
      const { code, msg, data } = await OrphanServer.orphan_good_mark(params)
      if (code != 200) {
        message.error(msg)
        return
      }
      if (data) {
        message.success("标记成功")
        //页码为1
        // setParamsInfo({ ...ParamsInfo, pageNum: 1 })
        //丢失商品列表
        setTimeout(getOrphan, 0)
      }
    } catch (error) {
      console.log(error)
    }
  }

  return (
    // 称重列表
    <div>
      <Card>
        <div className={styles.loss_prevention_list_head}>
          <div className={styles.loss_prevention_list_head_search}>
            <div className={styles.loss_prevention_list_head_search_child}>
              商品名称：
              <PubInput
                onChange={(val) => {
                  setParamsInfo({
                    ...ParamsInfo,
                    skuName: val,
                  })
                }}
                width="180px"
                placeholder="商品名称"
                value={ParamsInfo.skuName}
              />
            </div>
            <div className={styles.loss_prevention_list_head_search_child}>
              商品条码：
              <PubInput
                onChange={(val) => {
                  setParamsInfo({
                    ...ParamsInfo,
                    barCode: val,
                  })
                }}
                width="180px"
                placeholder="商品条码"
                value={ParamsInfo.barCode}
              />
            </div>
            <div className={styles.loss_prevention_list_head_search_child}>
              门店名称：
              <Select
                // mode="multiple"
                getPopupContainer={(triggerNode) => triggerNode.parentNode}
                placeholder="请选择门店"
                style={{ width: 180 }}
                allowClear
                value={ParamsInfo.shopId}
                showSearch
                filterOption={(i, o) => o.props.children.includes(i)}
                onChange={(e) => {
                  setParamsInfo({
                    ...ParamsInfo,
                    shopId: e || undefined,
                  })
                }}
              >
                {shopList.map((item, index) => {
                  return (
                    <Option value={item?.shopId} key={index}>
                      {item?.shopName}
                    </Option>
                  )
                })}
              </Select>
            </div>
            <div className={styles.loss_prevention_list_head_search_child}>
              生成时间：
              <PubRangePicker
                value={ParamsInfo?.timeArr}
                onChange={(val) => {
                  setParamsInfo({
                    ...ParamsInfo,
                    startDate: val.startDate, // 开始时间
                    endDate: val.endDate, // 结束时间
                    timeArr: val.TotalTime, // 总时间
                  })
                }}
              />
            </div>
            <div className={styles.loss_prevention_list_head_search_child}>
              标记：
              <Select
                // mode="multiple"
                getPopupContainer={(triggerNode) => triggerNode.parentNode}
                placeholder="请选择标记"
                style={{ width: 180 }}
                allowClear
                value={ParamsInfo.mark}
                showSearch
                filterOption={(i, o) => o.props.children.includes(i)}
                onChange={(e) => {
                  setParamsInfo({
                    ...ParamsInfo,
                    mark: e || undefined,
                  })
                }}
              >
                <Option value={1} key={1}>
                  未找回
                </Option>
                <Option value={2} key={2}>
                  无需找回-已收银
                </Option>
                <Option value={3} key={3}>
                  已找回
                </Option>
              </Select>
            </div>
          </div>
          <div className={styles.loss_prevention_list_head_btn}>
            <Button onClick={handClearn}>重置</Button>
            <Button type="primary" onClick={handlSearch}>
              查询
            </Button>
          </div>
        </div>
      </Card>
      <Card style={{ marginTop: 16 }}>
        <div style={{ marginBottom: "16px" }}>
          <Button type="primary" onClick={onExpoerr}>
            导出数据
          </Button>
        </div>
        <div>
          <Table
            columns={columsData}
            pagination={onTableChange()}
            dataSource={orphanList}
            rowKey="id"
            loading={loading}
            scroll={{ x: 2000, y: window.screen.height * 0.485 }}
          />
        </div>
      </Card>

      <Modal
        keyboard
        title="查看录像"
        open={isModalOpen}
        maskClosable
        footer={[]}
        width={"60%"}
        onCancel={() => {
          setIsModalOpen(false)
        }}
      >
        <div className={styles.policeroad_imgmodal}>
          <div className={styles.policeroad_imgmodal_left}>
            {typeNum == 1 ? (
              <video
                ref={myRef}
                width="100%"
                height="100%"
                muted
                controls
                autoPlay
              ></video>
            ) : typeNum == 2 ? (
              <>
                {/* // PlayError*/}
                {/* Loading展示 */}
                <div
                  className={styles.policeroad_imgmodal_leftmenceng}
                  style={{
                    display: PlayingLoading ? "" : "none",
                  }}
                >
                  <Spin size="large" />
                  <span style={{ fontSize: "20px", color: "grey" }}>
                    录像加载中请稍等……
                  </span>
                </div>
                {/* 错误展示 */}
                <div
                  className={styles.policeroad_imgmodal_leftmenceng}
                  style={{
                    display: PlayError && !PlayingLoading ? "" : "none",
                  }}
                >
                  <WarningOutlined style={{ fontSize: "40px", color: "red" }} />
                  <span style={{ fontSize: "20px", color: "grey" }}>
                    {PlayErrorText}
                  </span>
                </div>
                <div
                  id="video-container"
                  style={{
                    width: "100%",
                    height: "calc(100% - 50px)",
                    display: !PlayError && !PlayingLoading ? "" : "none",
                  }}
                  onClick={() => {
                    // player?.fullscreen()
                  }}
                ></div>
                {/* 操作按钮 */}

                {PlayError? null : (
                  <div className={styles.play_btn}>
                    {/* 暂停 / 播放 */}
                    <div
                      className={styles.playicon}
                      onClick={() => {
                        var player = getPlayer()
                        if (player.isPlaying()) {
                          player.pause()
                          setPlayStop(true)
                        } else {
                          player.play()
                          setPlayStop(false)
                        }
                      }}
                    >
                      {PlayStop ? (
                        <CaretRightOutlined style={{ fontSize: "20px" }} />
                      ) : (
                        <PauseOutlined style={{ fontSize: "20px" }} />
                      )}
                    </div>
                    {/* 播放倍速 */}
                    <Select
                      placeholder="请选择播放速度"
                      value={PlayScale}
                      onChange={(e) => {
                        setPlayScale(e ? e : 1)
                        var player = getPlayer()
                        player.setPlaybackConfig({
                          scale: e ? e : 1,
                        })
                        setPlayStop(false)
                      }}
                    >
                      <Option value={1} key={1}>
                        1倍播速
                      </Option>
                      <Option value={2} key={2}>
                        2倍播速
                      </Option>
                      <Option value={4} key={4}>
                        4倍播速
                      </Option>
                      {/* <Option value={16} key={16}>
                        16倍播速
                      </Option> */}
                    </Select>
                    <Button onClick={onBack}>后退10秒</Button>
                    <Button onClick={onSecond}>前进10秒</Button>
                    {/* <div>{nowPlaybackTime}</div> */}
                  </div>
                )}
              </>
            ) : null}
          </div>
        </div>
      </Modal>

      {/* 动态表头 */}
      <Drawer
        keyboard
        title="动态表头"
        open={columsOpen}
        onClose={() => {
          setColumsOpen(false)
        }}
      >
        {columsTitle.map((v) => {
          return (
            <div className={styles.colums_title}>
              <Checkbox
                className={styles.colums_title_checkBox}
                checked={v.isShow}
                onChange={() => {
                  setColumsTitle((prevColumsTitle) =>
                    prevColumsTitle.map((w) =>
                      w.title === v.title ? { ...w, isShow: !w.isShow } : w
                    )
                  )
                }}
              >
                {v.title}
              </Checkbox>
              <br />
            </div>
          )
        })}
      </Drawer>
    </div>
  )
}

export default Index
