import { POST, GET } from "../../utils/request"
import { stringify } from "qs"
import moment from "moment"
import request, { newRequest } from "../../utils/newRequest"

/**
 * 获取称重统计列表
 */
export const PlaybackCamera = (params) =>
  POST("/data/center/v1/device/monitor/camera/playback", params)

/**
 * 门店列表接口
 */
export const Shop_List = (params) =>
  // GET(`/data/center/v1/shop/list?${stringify(params)}`)
  // POST("/data/center/v1/shop/list", params)
  POST("/data/center/v1/shop/list", params)

/**
 * 获取门店下商品信息
 */
export const Shop_Sku_List = (params) =>
  GET(`/data/center/v1/goods/shop/sku/list?${stringify(params)}`)

/**
 * 商品图片库icon列表
 */
export const IconList = (params) => {
  return GET(
    `/data/center/v1/goods/icon/list?${stringify(params, {
      arrayFormat: "repeat",
    })}`
  )
}

/**
 * 丢失商品列表
 */
export const orphan_good_list = (params) =>
  GET(`/data/center/v1/goods/orphan/goods/list?${stringify(params)}`)

/**
 * 丢失商品标记
 */
export const orphan_good_mark = (params) =>
  POST("/data/center/v1/goods/orphan/goods/mark", params)

/**
 * 丢失商品规则列表
 */
export const orphan_goods_rulelist = (params) =>
  GET(`/data/center/v1/setting/orphan/goods/list?${stringify(params)}`)

/**
 * 丢失商品规则开启关闭
 */
export const orphan_good_ruleset = (params) =>
  POST("/data/center/v1/setting/orphan/goods/switch", params)

/**
 * 添加新的丢失商品规则
 */
export const orphan_good_add = (params) =>
  POST("/data/center/v1/setting/orphan/goods/add", params)

/**
 * 编辑丢失商品规则
 */
export const orphan_good_edit = (params) =>
  POST("/data/center/v1/setting/orphan/goods/edit", params)

/**
 * 丢失商品规则详情
 */
export const orphan_good_detail = (id) =>
  GET(`/data/center/v1/setting/orphan/goods/detail?id=${id}`)

/**
 * 丢失商品规则删除
 */
export const orphan_good_delete = (id) =>
  GET(`/data/center/v1/setting/orphan/goods/delete?id=${id}`)

/**
 * 丢失商品导出
 */
export async function GoodsExport(params) {
  return newRequest(
    `/data/center/v1/goods/orphan/goods/export?${stringify(params)}`,
    {
      responseType: "blob",
    }
  )
}

/**
 * 不生效设备：
 */
export const Devices_ListOne = (params) =>
  POST("/data/center/v1/shop/device/list", params)

